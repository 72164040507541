
import about from '../../../assets/img/frag/banner/about-mg.jpg'
function AboutHome() {
    return (
        <>
            <section className="aboutHome">
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-6">
                            <div className="about-containt text-center">
                                <span>A Few Words About Our Alarabian Fragrance</span>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes</p>
                                <a className="button button-default" href="#">Read more</a>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className="about-img">
                                <img src={about} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AboutHome
import fertilizer4 from "../../assets/img/products/4.jpg";
import { BsFillCartFill } from "react-icons/bs"
import { FiSearch } from "react-icons/fi"
import { GrAdd } from "react-icons/gr"
import { Link } from "react-router-dom";
import { ImgesData } from "../home/proImgs/ImgesData";

function CatogaryItem({ item, i, handleShow }) {
    const curr = window.localStorage.getItem('currencySym')
    let currencySymbol = curr
    if (currencySymbol === 'undefined') {
        currencySymbol = 'ZK'
    }
    return <div className="col-lg-3 col-md-6 col-sm-12" key={item._id}>
        <div className="featuredInfo">
            <div className="featuredFigure">
                <div className="featuredImg" style={{ display: "flex", justifyContent: "center" }}>
                    <Link to={`/product/${item?._id}`}>
                        {item?.variations[0]?.mainImage_url.url ? <img src={item?.variations[0]?.mainImage_url.url} alt="Product" className="imgProduct" /> : <img src={fertilizer4} alt="Product" />}
                    </Link>
                    <div className="quickView">
                        <ul>
                            <li className="viewProduct">
                                <button
                                    className="quick_view_btn"
                                    onClick={(e) => {
                                        handleShow(item._id)
                                    }}
                                >
                                    <FiSearch />
                                </button>
                            </li>
                            <li className="addProduct">
                                <Link to="/products">
                                    <GrAdd />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul className="hotList">
                    <li>
                        <Link to="/products">hot</Link>
                    </li>
                    <li>
                        <Link to="products">-20%</Link>
                    </li>
                </ul>
            </div>
            <div className="featuredContent">
                <h6>category</h6>
                <h5>
                    <Link to={`/product/${item._id}`}>{item.name}</Link>
                </h5>
                {/* <Rating /> */}
                {item?.variations?.length && <div className="rateDigit">
                    <span className="cross">{currencySymbol} {item?.variations[0]?.mrp}</span>
                    <span className="currentPrice">{currencySymbol}  {item?.variations[0]?.sale_rate}</span>
                </div>}


                <div className="buyNowInfo">

                    <Link to={`/product/${item._id}`} className="btn btn-danger addCart">View Detail</Link>
                    <Link to="#" className="btn btn-primary buyNow">
                        <BsFillCartFill /> Buy Now
                    </Link>
                </div>
                <div className="productDesc">
                    <p>{item.meta_description}</p>
                </div>
                <div className="featuredOption">
                    <select defaultValue={"DEFAULT"}>
                        <option value="DEFAULT">Select Option</option>
                        <option value="one">One</option>
                        <option value="two">Two</option>
                    </select>
                </div>
            </div>
        </div>



    </div>
}
export default CatogaryItem
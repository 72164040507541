import React from "react";
import { useGetCategoriesQuery, useGetMenuListQuery } from "../../products/productSlice";

import "./ProductsCategory.css";
import { productData } from "../../../pages/product-detail/mockData";

import c1 from '../../../assets/img/frag/category/c1.jpeg'
import c2 from '../../../assets/img/frag/category/c2.jpeg'
import c3 from '../../../assets/img/frag/category/c3.png'
import c4 from '../../../assets/img/frag/category/c4.jpeg'
import c5 from '../../../assets/img/frag/category/c5.jpg'
import c6 from '../../../assets/img/frag/category/c6.jpg'
import c7 from '../../../assets/img/frag/category/c7.jpg'
import c8 from '../../../assets/img/frag/category/c8.jpg'
import { Link } from "react-router-dom";

const mockData = [
  { _id: '1', img: c1, name: 'New Arrivals' },
  { _id: '2', img: c2, name: 'Men Perfume' },
  { _id: '3', img: c3, name: 'Natural Attar' },
  { _id: '4', img: c4, name: 'Natural Attar' },
  { _id: '5', img: c5, name: 'Women Perfume' },
  { _id: '6', img: c6, name: 'Fitness Lovers Perfume' },
  { _id: '7', img: c7, name: 'Wedding Perfume' },
  { _id: '9', img: c8, name: 'Winter Perfume' },
  { _id: '10', img: c1, name: 'Office Perfume' },
  { _id: '11', img: c1, name: 'Everyday Perfume' },
  { _id: '12', img: c1, name: 'Attar' },
]
function ProductsCategory() {
  const { data, isLoading, error } = useGetMenuListQuery();

  return (
    <>
      <section className="productsCategorySec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fisherman-content mb-3">
                <h3>Shop by Categories</h3>
                <p>Let's shop by choosing the personalized categories </p>
              </div>
            </div>

            {isLoading && <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <h4 style={{ textAlign: "center", color: "red" }}>Server Error</h4>}

            {data?.slice(0, 8).map((item, i) => {
              console.log('cat', item);
              return <div key={item?.title?._id} className="col-lg-3 col-md-6 col-sm-12">
                <div className="productsCategoryItem productsCategoryItem-2">
                  {/* <div className="categoryHeader">
                    <h5 className="title">{item.name}</h5>
                    <a href="/products">view all </a>
                  </div> */}
                  <div className="categoryBody">
                    <div className="singleProducts">
                      <Link to={`/product/category/${item?.title?._id}`}>
                        <img src={mockData[i]?.img} alt="Product" />
                        <p> Flower seeds</p>
                        <span className="sale">Sale</span>
                      </Link>
                    </div>
                    <div className="categoryHeader">
                      <h5 className="title">{item?.title?.name}</h5>
                      <Link to={`/product/category/${item?.title?._id}`}>view all </Link>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsCategory;

